import styled from "styled-components";
import { Link } from "gatsby";

export const StyledLinkInternal = styled(Link)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: inherit;
`;

export const StyledBlogPostLink = styled(Link)`
  color: ${({ theme }) => theme.palette.fluoro};
  font-size: 2rem;
  font-family: "Gruppo";
  font-weight: 600;
  text-decoration: none;
`;

export const StyledLinkExternal = styled.a`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: inherit;
`;
