import React from "react";
import styled from "styled-components";

export const BlogCard = ({ children }) => {
  return <Card>{children}</Card>;
};

const Card = styled.div`
  background: ${({ theme }) => theme.palette.grey[700]};
  padding: 1rem;
  border-right: 2px solid ${({ theme }) => theme.palette.red};
  border-left: 2px solid ${({ theme }) => theme.palette.red};
  border-radius: 5px;
  max-width: 80ch;
  margin: 0 auto;
`;
