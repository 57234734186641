export const theme = {
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    grey: {
      50: "#fefefe",
      100: "#f5f5f5",
      200: "#f1f1f1",
      300: "#eaeaea",
      400: "#e4e4e4",
      500: "#adadad",
      600: "#bfbfbf",
      700: "#505050",
      800: "#323232",
      900: "#212121",
    },
    darkBlue: "hsl(239, 35%, 39%)",
    duskyBlue: "hsl(194, 72%, 34%)",
    fluoro: "hsl(182, 81%, 56%)",
    red: "hsl(354, 99%, 38%)",
  },
  breakpoints: {
    values: {
      xs: "0px",
      sm: "600px",
      md: "960px",
      lg: "1280px",
      xl: "1920px",
    },
    up: (breakpoint) => `@media (min-width: ${breakpoint})`,
    down: (breakpoint) => `@media (max-width: ${breakpoint})`,
  },
};
