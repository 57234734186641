import styled from "styled-components";

export const Heading = styled.h1`
  font-size: 2rem;
  font-family: "Gruppo";
  font-weight: 600;
  color: ${({ theme }) => theme.palette.fluoro};
`;

export const BlogSubHeading = styled.h2`
  font-size: 1.3rem;
  color: ${({ theme }) => theme.palette.fluoro};
  margin-top: 1rem;
`;

export const BodyText = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.common.white};
  margin-top: 1rem;
`;

export const ListItem = styled.li`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.common.white};
  margin-top: 1rem;
`;

export const SmallText = styled.p`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.common.white};
  margin-top: 1rem;
`;
