import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../styles/theme";
import earth from "../assets/earth.svg";
import {
  SmallText,
  BodyText,
  BlogSubHeading,
  Heading,
  ListItem,
} from "../components/Typography";
import {
  StyledLinkInternal,
  StyledLinkExternal,
} from "../components/StyledLink";
import { MDXProvider } from "@mdx-js/react";
import { BlogCard } from "../components/BlogCard";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import CodeBlock from "../components/CodeBlock";

const mdxComponents = {
  BlogCard,
  p: BodyText,
  h1: Heading,
  h2: BlogSubHeading,
  a: StyledLinkExternal,
  li: ListItem,
  // eslint-disable-next-line react/display-name
  pre: (props) => <div {...props} />,
  code: CodeBlock,
};

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>{data.site.siteMetadata.title}</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <MDXProvider components={mdxComponents}>
          <DottedBackground>
            <Header>
              <ImgLink to="/">
                <img src={earth} alt={"a planet"} />
                Home
              </ImgLink>
            </Header>
            <MainContent>{children}</MainContent>
            <Footer>
              <SmallText>
                Icons made by{" "}
                <StyledLinkExternal href="https://www.freepik.com">
                  Freepik
                </StyledLinkExternal>{" "}
                from{" "}
                <StyledLinkExternal href="https://www.flaticon.com/">
                  www.flaticon.com
                </StyledLinkExternal>
              </SmallText>
              <ContactLinkWrapper>
                <StyledLinkExternal href="https://twitter.com/Jo_Rigg">
                  Twitter
                </StyledLinkExternal>
                <StyledLinkExternal href="https://github.com/Jo-R">
                  Github
                </StyledLinkExternal>
                <StyledLinkExternal href="https://www.linkedin.com/in/jorigg/">
                  LinkedIn
                </StyledLinkExternal>
              </ContactLinkWrapper>
            </Footer>
          </DottedBackground>
        </MDXProvider>
      </ThemeProvider>
    </>
  );
};

export default Layout;

const DottedBackground = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.palette.grey[900]};
  background-image: ${({ theme }) =>
    `radial-gradient(${theme.palette.darkBlue} 5%, transparent 0);`}
  background-size: 30px 30px;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto 1fr auto;
`;

const Header = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`;

const ImgLink = styled(StyledLinkInternal)`
  font-size: 0.8rem;
  text-decoration: none;
  padding-right: 2rem;
  text-align: center;
  > img {
    width: 50px;
    height: 50px;
  }
`;

const MainContent = styled.main`
  padding: 1rem 2rem;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`;

const ContactLinkWrapper = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.2rem;
  ${({
    theme: {
      breakpoints: { down, values },
    },
  }) => down(values.sm)} {
    grid-template-columns: 1fr;
  }
`;
